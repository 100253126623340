import '../sass/sass.scss';
import './functions.js';

import Vue from 'vue';

Vue.prototype.$app = app;
Vue.prototype.$meta = meta;
Vue.config.productionTip = false;

// main
Vue.component('login', require('./components/login').default);
//Vue.component('register', require('./components/register').default);
Vue.component('forgot-password', require('./components/forgot-password').default);
Vue.component('reset-password', require('./components/reset-password').default);
Vue.component('contact', require('./components/contact').default);
//Vue.component('user-profile', require('./components/user-profile').default);

new Vue({
	el: '#app',
});