<template>
	<div id="forgot-password">
		<form @submit.prevent="passwordResetForm">
			<div class="form-block">
				<label class="form-label">
					<span class="label">Email address</span>
					<div class="form-field input">
						<input type="email" v-model="email" class="field" maxlength="64" placeholder="Email address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" >
					</div>
				</label>
			</div>
			<div>
				<button type="submit" class="ui-btn ui-btn-block" v-text="sending ? 'Sending...' : 'Forgot Password'" :disabled="sending || !email.length">Forgot Password</button>
			</div>
			<div class="messages ui-text-center" v-if="message">
				<p v-text="message"></p>
			</div>
		</form>
	</div>
</template>;
<script>
	import Config from '../../config' 
	import {userApi} from '../../api'
	export default {
		data () {
			return {
				sending: false,
				message: "",
				email: '',
			}
		},
		methods: {
			passwordResetForm() {
				let self = this;
				self.sending = true;
				self.message = "";
				userApi.forgotPassword({
					email: self.email
				}).then(response => {
					if(!response.data.error) {
						self.email = '';
					}
					self.message = response.data.message;
					self.sending = false;
				}).catch(error => {
					alert('Something went wrong');
					console.log(error);
					self.sending = false;
				});
			}
		}
	}
</script>