import { render, staticRenderFns } from "./index.vue?vue&type=template&id=32051aac&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\wamp64\\www\\www.compareethereum.com\\wp-content\\themes\\compare-ethereum\\node_modules\\vue-loader\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32051aac')) {
      api.createRecord('32051aac', component.options)
    } else {
      api.reload('32051aac', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=32051aac&", function () {
      api.rerender('32051aac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reset-password/index.vue"
export default component.exports