var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "forgot-password" } }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.passwordResetForm($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-block" }, [
          _c("label", { staticClass: "form-label" }, [
            _c("span", { staticClass: "label" }, [_vm._v("Email address")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-field input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                staticClass: "field",
                attrs: {
                  type: "email",
                  maxlength: "64",
                  placeholder: "Email address",
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$"
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "ui-btn ui-btn-block",
              attrs: {
                type: "submit",
                disabled: _vm.sending || !_vm.email.length
              },
              domProps: {
                textContent: _vm._s(
                  _vm.sending ? "Sending..." : "Forgot Password"
                )
              }
            },
            [_vm._v("Forgot Password")]
          )
        ]),
        _vm._v(" "),
        _vm.message
          ? _c("div", { staticClass: "messages ui-text-center" }, [
              _c("p", { domProps: { textContent: _vm._s(_vm.message) } })
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }