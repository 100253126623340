var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "contact-form" } }, [
    _c("h2", { staticClass: "heading" }, [_vm._v("Get in touch")]),
    _vm._v(" "),
    _c("p", [_vm._v("Use the form below to get in touch with us")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.processContact($event)
          }
        }
      },
      [
        _c("div", { staticClass: "col-row" }, [
          _c("div", { staticClass: "col l6 m6 s12" }, [
            _c("div", { staticClass: "form-block" }, [
              _c(
                "label",
                {
                  staticClass: "form-label required",
                  attrs: { id: "your-firstname" }
                },
                [
                  _c("div", { staticClass: "form-field input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.firstname,
                          expression: "model.firstname"
                        }
                      ],
                      staticClass: "field",
                      attrs: { placeholder: "Firstname", type: "text" },
                      domProps: { value: _vm.model.firstname },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "firstname", $event.target.value)
                        }
                      }
                    })
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-block" }, [
              _c(
                "label",
                {
                  staticClass: "form-label required",
                  attrs: { id: "your-surname" }
                },
                [
                  _c("div", { staticClass: "form-field input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.surname,
                          expression: "model.surname"
                        }
                      ],
                      staticClass: "field",
                      attrs: { placeholder: "Surname", type: "text" },
                      domProps: { value: _vm.model.surname },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "surname", $event.target.value)
                        }
                      }
                    })
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-block" }, [
              _c(
                "label",
                {
                  staticClass: "form-label required",
                  attrs: { id: "your-email" }
                },
                [
                  _c("div", { staticClass: "form-field input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.email,
                          expression: "model.email"
                        }
                      ],
                      staticClass: "field",
                      attrs: {
                        placeholder: "Email address",
                        type: "email",
                        maxlength: "64",
                        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$"
                      },
                      domProps: { value: _vm.model.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "email", $event.target.value)
                        }
                      }
                    })
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col l6 m6 s12" }, [
            _c("div", { staticClass: "form-block" }, [
              _c(
                "label",
                { staticClass: "form-label", attrs: { id: "your-message" } },
                [
                  _c("div", { staticClass: "form-field textarea" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.message,
                          expression: "model.message"
                        }
                      ],
                      staticClass: "field",
                      attrs: { placeholder: "Message" },
                      domProps: { value: _vm.model.message },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "message", $event.target.value)
                        }
                      }
                    })
                  ])
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-block" }, [
          _c(
            "div",
            { staticClass: "form-label required", attrs: { id: "your-terms" } },
            [
              _c("div", { staticClass: "ui-checkbox" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.terms,
                        expression: "model.terms"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.model.terms)
                        ? _vm._i(_vm.model.terms, null) > -1
                        : _vm.model.terms
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.model.terms,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.model, "terms", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                "terms",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.model, "terms", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkbox" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("I agree to the T&Cs")])
                ])
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "submit" }, [
          _c(
            "button",
            {
              staticClass: "ui-btn ui-btn-block",
              attrs: {
                type: "submit",
                disabled: _vm.sending || !_vm.model.terms
              },
              domProps: {
                textContent: _vm._s(_vm.sending ? "Sending..." : "Send message")
              }
            },
            [_vm._v("Send message")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }