<template>
	<div id="login-form">
		<div class="modal">
			<div class="modal-area">
				<h6 class="heading-line center">Login</h6>
				<form @submit.prevent="auth" autocomplete="on">
					<span class="close fa fa-times"></span>
					<div class="form-block">
						<label class="form-label">
							<span class="label">Username or email address</span>
							<div class="form-field input">
								<input type="text" v-model="user.user" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label">
							<span class="label">Password</span>
							<div class="form-field input">
								<input type="password" v-model="user.password" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<div class="ui-checkbox">
							<label>
								<input type="checkbox" v-model="user.remember" />
								<span class="checkbox"></span>
								<span>Remember me</span>
							</label>
						</div>
					</div>
					<div class="login">
						<button type="submit" class="ui-btn ui-btn-block" v-text="sending ? 'Logging you in...' : 'Login'" :disabled="sending">Login</button>
					</div>
					<div class="messages ui-text-center" v-if="message">
						<p v-text="message"></p>
					</div>
					<p class="cta">
						<a :href="`${$app.site_url}/forgot-password`">Forgot Password</a>
					</p>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
	import {userApi} from '../../api';
	export default {
		data () {
			return {
				sending: false,
				message: "",
				user: {
					user: "",
					password: "",
					remember: false
				}
			}
		},
		methods: {
			auth () {
				let self = this,
					user = self.user;
				self.sending = true;
				let model = {
					user: user.user,
					password: btoa(user.password),
					remember: user.remember
				}
				userApi.auth(model).then(response => {
					self.message = response.data.message;
					if(response.data.error) {
						self.sending = false;
						$('.modal-area').addClass('animated shake');
						setTimeout(function() {
							$('.modal-area').removeClass('animated shake');
						}, 2000);
					} else {
						window.location.reload();
					}
				}, (error) => {
					self.message = error.response.data.message;
					self.sending = false;
					console.log(error);
				});
			},
		},
	}
</script>