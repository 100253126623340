import http from '../http';

const userApi = {
	auth(data) {
		return http.post('/user/auth', data);
	},
	register(data) {
		return http.post('/user/register', data);
	},
	update(data) {
		return http.put('/user', data);
	},
	forgotPassword(data) {
		return http.post('/user/forgot-password', data);
	},
	resetPassword(data) {
		return http.post('/user/reset-password', data);
	},
	removeMyAccount() {
		return http.delete('/user');
	}
}

const contactApi = {
	contact(data) {
		return http.post('/contact', data);
	}
}

const recaptchaApi = {
	validateRecaptcha(token) {
		return http.post('/validate-recaptcha', {token: token});
	}
}

const postsApi = {
	getPosts(args) {
		return http.get('/posts?' + $.param(args));
	},
	getCases(args) {
		return http.get('/case-studies?' + $.param(args));
	},
	getVideos(args) {
		return http.get('/videos?' + $.param(args));
	},
	getPostsByTax(tax = 'category', slug, args) {
		return http.get(`/${tax}/${slug}/posts?` + $.param(args));
	},
	searchPosts(args) {
		return http.get('/search/posts?' + $.param(args));
	}
}

const termsApi = {
	getTermsByTax(args) {
		return http.get('/terms?' + $.param(args));
	},
}

export {
	userApi,
	contactApi,
	recaptchaApi,
	postsApi,
	termsApi
}