import 'owl.carousel';
import helper from './helpers';
import 'jquery.scrollto';
import 'jquery.localscroll';
// scroll header
function ScrollHeader() {
	var scrollTarget = $('#header'),
		scrollClass = 'collapse';
	if(helper.isElementScrolledTo(scrollTarget[0].id)) {
		scrollTarget.addClass(scrollClass);
	} else {
		scrollTarget.removeClass(scrollClass);
	}
}
// trigger login
function triggerLoginForm() {
	var $class = 'active',
		$target = $('#login-form'),
		$login = $('.triggerLoginForm');
	$login.on('click', function() {
		$target.addClass($class);
		if($target.hasClass($class)) {
			$target.find('.close').on('click', function() {
				$target.removeClass($class);
			});
		}
		$target.on('click', function(e) {
			if(e.target.id === 'login-form') {
				$target.removeClass($class);
			}
		});
	});
	if(helper.$_GET('login')) {
		setTimeout(function() {
			$login.trigger('click');
		});
	}
}
// set cookie policy
function enableCookies() {
	$('#cookie button').on('click', function() {
		helper.setCookie('cookie-accept', true, 365);
		$('#cookie').remove();
	});
}
function activateNavMenu() {
	// mobile nav
	$('#nav-menu').on('click', function() {
		var app = $('#app'),
			activeClass = 'menu-active';
		app.toggleClass(activeClass);
		$(this).toggleClass('fa-times');
	});
}
// ui toggle
function enableUIToggle() {
	var toggle = $('.ui-toggle');
	toggle.each(function() {
		var load = $(this).data('toggle');
		if(load == 'show') {
			$(this).find('.ui-toggle-content').css("display", "block");
		}
	});
	$('.ui-toggle-title').on('click', function() {
		$(this).next('.ui-toggle-content').toggle();
	});
}
// ui checbox access
function doCheckboxAccess() {
	$('.ui-checkbox input').on('keypress', function(e) {
		e.preventDefault();
		if(e.keyCode === 13) {
			$(this).prop('checked', !$(this).prop('checked'));
		}
	});
}
jQuery(document).ready(function($) {
	$.localScroll({
		duration: 500,
		offset: -81
	});
	// nav scroll
	ScrollHeader();
	// tabs
	helper.enableTabs();
	// trigger login form
	triggerLoginForm();
	// enable cookies
	enableCookies();
	// activate nav menu
	activateNavMenu();
	// ui select
	helper.enableUISelect();
	// ui toggle
	enableUIToggle();
	// checkbox access
	doCheckboxAccess();
	// on scroll
	$(window).on('scroll', function() {
		// if scroll meets end of page, stop scrolling
		if($(window).scrollTop() + $(window).height() != $(document).height()) {
			ScrollHeader();
		}
		if($(window).scrollTop() > 300) {
			$('#backToTop').show();
		} else {
			$('#backToTop').hide();
		}
	});
	// on resize
	$(window).on('resize', function() {
	});

});