<template>
	<div>
		<form @submit.prevent="resetPassword">
			<div class="form-block">
				<label class="form-label">
					<span class="label">Enter New Password</span>
					<div class="form-field input">
						<input type="password" v-model="password" class="field" placeholder="Password" required>
					</div>
				</label>
			</div>
			<div>
				<button type="submit" class="ui-btn ui-btn-block" v-text="sending ? 'Setting Your Password...' : 'Reset Password'" :disabled="sending || !password.length">Reset Password</button>
			</div>
			<div class="messages ui-text-center" v-if="message">
				<p v-text="message"></p>
			</div>
		</form>
	</div>	
</template>
<script>
	import {userApi} from '../../api';
	import helpers from '../../helpers';
	import config from '../../config';
	export default {
		data () {
			return {
				sending: false,
				message: "",
				password: '',
			}
		},
		methods: {
			resetPassword() {
				let self = this;
				self.sending = true;
				self.message = "";
				userApi.resetPassword({
					password: btoa(self.password),
					key: helpers.$_GET('key')
				}).then(response => {
					self.sending = false;
					self.message = response.data.message;
					if(!response.data.error) {
						setTimeout(function() {
							window.location.href = `${config.site_url}/login`;
						}, 1000);
					}
				}).catch(error => {
					alert("Something went wrong");
					console.log(error);
					self.sending = false;
				});
			}
		}
	}
</script>