<template>
	<div id="contact-form">
		<h2 class="heading">Get in touch</h2>
		<p>Use the form below to get in touch with us</p>
		<form @submit.prevent="processContact">
			<div class="col-row">
				<div class="col l6 m6 s12">
					<div class="form-block">
						<label id="your-firstname" class="form-label required">
							<div class="form-field input">
								<input placeholder="Firstname" v-model="model.firstname" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label id="your-surname" class="form-label required">
							<div class="form-field input">
								<input placeholder="Surname" v-model="model.surname" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label id="your-email" class="form-label required">
							<div class="form-field input">
								<input placeholder="Email address" v-model="model.email" type="email" maxlength="64" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" class="field">
							</div>
						</label>
					</div>
				</div>
				<div class="col l6 m6 s12">
					<div class="form-block">
						<label id="your-message" class="form-label">
							<div class="form-field textarea">
								<textarea placeholder="Message" v-model="model.message" class="field"></textarea>
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="form-block">
				<div id="your-terms" class="form-label required">
					<div class="ui-checkbox">
						<label>
							<input type="checkbox" v-model="model.terms">
							<span class="checkbox"></span>
							<span>I agree to the T&amp;Cs</span>
						</label>
					</div>
				</div>
			</div>
			<div class="submit">
				<button type="submit" class="ui-btn ui-btn-block" v-text="sending ? 'Sending...' : 'Send message'" :disabled="sending || !model.terms">Send message</button>
			</div>
		</form>
	</div>
</template>
<script>
	import helper from '../../helpers';
	import {contactApi} from '../../api';
	export default {
		data () {
			return {
				sending: false,
				model: {}
			}
		},
		methods: {
			contact() {
				let self = this;
				self.sending = true;
				$('span.error').remove();
				contactApi.contact(self.model).then(response => {
					self.sending = false;
					let res = response.data;
					if(!res.error) {
						alert(res.message);
						self.initModel();
					}
				}, (error) => {
					self.sending = false;
					let res = error.response.data;
					if(res.error) {
						if(res.hasOwnProperty("errors")) {
							res.errors.forEach((e) => {
								$('#' + e.key).append('<span class="error">' + e.error + '</span>');
							});
						}
					}
					if(res.message) {
						alert(res.message);
					}
					console.log(error);
				});
			},
			processContact() {
				let self = this;
				self.contact();
			},
			initModel() {
				let self = this;
				self.model = {
					firstname: "",
					surname: "",
					email: "",
					message: "",
					terms: false
				}
			},
			initMounted() {
				let self = this;
				self.initModel();
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>